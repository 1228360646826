import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Gallery from "../components/gallery"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const imagesDesktop = data.allCanvasPosts.edges.map(({ node }) => ({
    title: node.title,
    subtitle: node.subtitle,
    image:
      node.featuredMedia && node.featuredMedia.localFile.childImageSharp
        ? node.featuredMedia.localFile.childImageSharp.desktop
        : null,
  }))
  const imagesMobile = data.allCanvasPosts.edges.map(({ node }) => ({
    title: node.title,
    subtitle: node.subtitle,
    image:
      node.featuredMedia && node.featuredMedia.localFile.childImageSharp
        ? node.featuredMedia.localFile.childImageSharp.mobile
        : null,
  }))
  return (
    <Layout>
      <SEO title="Portfolio" />
      <Gallery imagesMobile={imagesMobile} imagesDesktop={imagesDesktop} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allCanvasPosts: allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { elemMatch: { name: { eq: "Canvas" } } } }
    ) {
      edges {
        node {
          date
          title
          subtitle: content
          featuredMedia: featured_media {
            localFile {
              childImageSharp {
                id
                mobile: fluid(
                  maxWidth: 1300
                  srcSetBreakpoints: [700, 900, 1010, 1300]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                desktop: fixed(height: 900) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          categories {
            name
          }
        }
      }
    }
  }
`

export default IndexPage
